import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import authorization from '@/store/utils/authorization'
import systemConfig from '@/config/system-config'

Vue.use(VueRouter)

const routes = [
  {
    path: '/index',
    redirect: '/'
  },
  {
    path: '/',
    name: 'Index',
    component: () => {
      return import('@/views/front-pages/Index')
    }
  },
  {
    path: '/note_detail',
    name: 'notificationDetail',
    component: () => {
      return import('@/views/front-pages/notification/notificationDetail')
    }
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      menuCode: 'login_page',
      menuName: '登录'
    },
    component: () => {
      return import('@/views/login-page/Login')
    }
  },
  {
    path: '/404',
    name: 'NotFound',
    meta: {
      menuCode: 'not_found',
      menuName: '登录'
    },
    component: () => import('@/components/NotFound/index')
  },
  {
    path: '/logout',
    name: 'Logout',
    meta: {
      menuCode: 'logout_page',
      menuName: '登出'
    },
    component: () => {
      return import('@/views/login-page/Logout')
    }
  },
  {
    path: '/test',
    name: 'Test',
    component: () => {
      return import('@/views/manage-page/notification/Notification.vue')
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, then, next) => {
  // 页面跳转，中断所有未完成的请求
  store.dispatch('removeRequestList').finally(() => {
    // 审计数据同步
    if (systemConfig.LOG_ENABLE && to.meta) {
      store.commit('SET_CURRENT_MENU_ID', to.meta.menuId)
    }
    // 放行白名单
    if (systemConfig.ROUTER_WHITE_LIST.indexOf(to.name) !== -1) {
      return next()
    }
    // 过滤非法请求
    if (!authorization.getToken()) {
      // 清空缓存
      authorization.clearAllCache()
      return next('/login')
    }

    // 拦截损坏的用户信息
    if (!authorization.getUserInfo()) {
      console.log('账户信息异常')
      // 清空缓存，重新登录
      authorization.clearAllCache()
      return next('/login')
    }

    // 拦截损坏的路由
    if (!authorization.getUserRouter()) {
      console.error('路由缓存损坏')
      // 清空缓存，重新登录
      authorization.clearAllCache()
      return next('/login')
    }

    // 路由追加
    if (store.getters.getFlushFlag) {
      // 动态添加路由
      authorization.initializeUserRouter(authorization.getUserRouter()).then(() => {
        store.commit('setFlushFlag', false)
        return next({ ...to, replace: true })
      }).catch(err => {
        console.log('渲染路由失败：', err)
        // 清空缓存，重新登录
        authorization.clearAllCache()
        return next('/login')
      })
      // 加载完成菜单信息，执行跳转
      return next()
    }

    // 合法放行
    return next()
  })
})

export default router
